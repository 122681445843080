import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/Login.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/ask-tavolo',
    name: 'AskTavolo',

    component: () => import(/* webpackChunkName: "about" */ '../components/OpenAI.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/',
    name: 'Dashboard',

    component: () => import(/* webpackChunkName: "about" */ '../components/Dashboard.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/auto-marketing',
    name: 'AutoMarkeeting',

    component: () => import(/* webpackChunkName: "about" */ '../views/AutoMarkeeting.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/profile',
    name: 'Profile',

    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/customers',
    name: 'Customers',

    component: () => import(/* webpackChunkName: "about" */ '../views/Customers.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/schedule',
    name: 'Schedule',

    component: () => import(/* webpackChunkName: "about" */ '../views/Schedule.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'Signup',

    component: () => import(/* webpackChunkName: "about" */ '@/views/Signup.vue'),

  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  let auth = localStorage.getItem("token");

  console.log("my user", auth);

  // Not logged into a guarded route?
  if (to.meta.requiresAuth === true && auth == null) {
    console.log("requires auth, redirect to login");

    next({ name: "Login" });
  }

  // Logged in for an auth route
  else if (
    (to.name == "Login" || to.name == "Signup") &&
    auth != null
  ) {
    console.log("login or register, has a user so send home");
    next({ name: "Dashboard" });
  }

  // Carry On...
  else next();
});

export default router
