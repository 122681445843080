<template>
    <v-snackbar v-model="showSnackbar" :color="color" :timeout="timeout">
        {{ message }}
        <v-btn color="accent" text @click="closeSnackbar">{{ actionText }}</v-btn>
    </v-snackbar>
</template>

<script>
import EventBus from '../../global/event-bus';

export default {
    data() {
        return {
            showSnackbar: false,
            message: '',
            actionText: '',
            timeout: 3000, // Adjust the timeout duration as needed (in milliseconds)
            color: 'success', // Change this to match your desired color
        };
    },
    created() {
        EventBus.$on('show-snackbar', ({ message, actionText }) => {
            alert('here')
            this.message = message;
            this.actionText = actionText;
            this.showSnackbar = true;
        });
    },
    methods: {
        closeSnackbar() {
            this.showSnackbar = false;
        },
        showSnackbar() {
            alert('alskdj')
        }
    },
};
</script>
