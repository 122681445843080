<template>
  <v-row>

    <v-col md="2" v-if="$route.name != 'Login' && $route.name != 'Signup'">

      <div class="mt-6 ml-10" v-if="$route.name != 'Login' || $route.name != 'Signup'">
        <img src="@/assets/Tavolo.png" alt="" style="height: 20px; width: 100px;">
      </div>

      <v-card elevation="1" width="256" class="mt-10" v-if="$route.name != 'Login' || $route.name != 'Signup'">
        <v-navigation-drawer floating permanent>
          <v-list dense rounded class="pl-0">
            <v-list-item-group v-model="selectedItem" color="#8075FF" class="pl-0">
              <v-list-item v-for="item in items" :key="item.title" link class="pl-0">
                <v-list-item-icon class="mr-2">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <router-link class="v-list-item__content" :to="item.path">

                  <v-list-item-title class="link-text">{{ item.title }}</v-list-item-title>

                </router-link>

              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-card>
    </v-col>

    <!-- <OpenAI /> -->
    <v-col :md="$route.name != 'Login' && $route.name != 'Signup' ? 10 : 12">
      <div class="float-right mr-12 mt-3" v-if="$route.name != 'Login' && $route.name != 'Signup'">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Menu
            </v-btn>
          </template>
          <v-list>
            <v-list-item class="cursor-pointer" @click="logout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <router-view></router-view>

    </v-col>

  </v-row>
</template>

<script>


export default {
  name: 'Home',
  data() {
    return {
      items: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard', path: '/' },
        { title: 'Auto-Generated Marketing', icon: 'mdi-forum', path: '/auto-marketing' },
        { title: 'Ask Tavolo', icon: 'mdi-home-assistant', path: '/ask-tavolo' },
        { title: 'My Customers', icon: 'mdi-account', path: '/customers' },
        { title: 'Schedule Listing', icon: 'mdi-account', path: '/schedule' },


        { title: 'Profile', icon: 'mdi-account', path: '/profile' },


      ],


    }
  },
  methods: {
    changeRoute(path) {
      this.$router.push({ path: path })
    },
    logout() {
      localStorage.removeItem('token')
      this.$router.push('/login')
    }
  }

}
</script>

<style scoped>
.link-text {
  color: black;
  text-decoration: none !important;
}

router-link a {
  text-decoration: none !important;
}

a {

  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
