import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './Vee-validate'
import Snackbar from './components/common/Snackbar.vue';
Vue.component('Snackbar', Snackbar);

Vue.config.productionTip = false
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')


