<template>
    <div style="height: 125vh; background-image: linear-gradient(#00041E, #190B53, #6320EE, #6D3EF4, #8075FF)">
        <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout" top right>
            {{ snackbar.message }}
        </v-snackbar>
        <div style="position: relative; top: 15%;">
            <div class="d-flex justify-center align-center">
                <img src="@/assets/tavolo_svg.svg" alt="" height="118" width="600">
            </div>
            <ValidationObserver ref="obs" v-slot="{ handleSubmit }">
                <div class="d-flex justify-center ">
                    <v-form>
                        <div class="d-flex mt-10">
                            <span class="white-text mt-2 mr-16 font-weight-bold">
                                Email:
                            </span>
                            <div>
                                <BaseInput style="width: 354px;" rules="required|email" name="Email" type="email"
                                    v-model="email" />

                            </div>
                        </div>
                        <div class="d-flex">
                            <span class="white-text mt-2 mr-10 font-weight-bold">
                                Password:
                            </span>
                            <div>
                                <BaseInput type="password" style="width: 354px;" rules="required" name="Password"
                                    v-model="password" />

                            </div>
                        </div>
                        <div class="">
                            <v-btn block @click="handleSubmit(submit)" :loading="loading">
                                Login
                            </v-btn>
                        </div>

                        <span class="d-flex justify-space-between mt-4 cursor-pointer">
                            <span class="cursor-pointer white-text">Forgot password</span>
                            <router-link to="/signup">Register here</router-link>
                        </span>
                    </v-form>
                </div>
            </ValidationObserver>
        </div>



    </div>
</template>

<script>
import BaseInput from "@/components/common/BaseInput.vue";
import { ValidationObserver } from "vee-validate";

import { EventBus } from "../main";
import axios from '@/services/ApiService';


export default {
    name: "Login",
    props: {
        source: String,
    },
    components: { BaseInput, ValidationObserver },
    data() {
        return {
            snackbar: {
                show: false,
                message: '',
                color: '',
                timeout: 3000, // Snackbar timeout in milliseconds
            },
            email: "",
            password: "",
            user: null,
            loading: false,
        }
    },
    methods: {
        submit() {

            // console.log("submit form", ApiService);
            this.loading = true;
            let payload = {
                email: this.email,
                password: this.password,
            };

            axios
                .post('login', payload)
                .then((response) => {
                    console.log("user", response.data);
                    this.loading = false;
                    this.user = response.data;
                    if (this.user != null) {
                        console.log(response.data.token);
                        localStorage.setItem("token", response.data.token);
                        localStorage.setItem('userData', JSON.stringify(response.data))

                        this.$router.push("/");
                    }
                })
                .catch((err) => {

                    this.loading = false
                    this.snackbar.show = true
                    this.snackbar.message = err.response.data.message
                    this.snackbar.color = 'red accent-2'
                });
        },
    }
}
</script>

<style scoped>
.white-text {
    color: #F8F0FB;
}

::v-deep .v-input__slot {
    background-color: #F8F0FB !important;
    border-radius: 12px !important;
}

.cursor-pointer {
    cursor: pointer;
}

a {

    text-decoration: none;
    color: #F8F0FB !important;
}
</style>