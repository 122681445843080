// src/axios.js

import axios from 'axios';



const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL, // Replace with your API endpoint
});

// Request Interceptor
instance.interceptors.request.use(
    (config) => {
        // Add the API key to the request headers
        config.headers['Authorization'] = `Bearer ${localStorage.getItem("token")}`;

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default instance;
